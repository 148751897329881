@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 2px black;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}

body {
  background-color: rgb(255, 255, 251);
}
